import { Col, Container, Form, Row } from 'react-bootstrap';

import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../seo';
import SecuredCard from '../subComponents/securedCard';
import SubFooter from '../subComponents/subFooter';

const ShortFormLayout = (props) => {
  return (
    <>
      <SEO title='Quotes - MedicareFAQ' />
      <section className='common-section quote-sec'>
        <Container fluid>
          <Row className='step5-footer-spacer'>
            <Col
              xs={12}
              sm={12}
              md={12}
              // lg={8}
              lg={{ offset: 1, span: 10 }}
              className='quote-right-col-wrap'
            >
              <div className='quote-right-wrap'>
                <div className='quote-right-form-wrap'>
                  <Form
                    id={props.stepId}
                    onSubmit={props.formOnSubmit}
                    noValidate
                    validated={props.validated}
                  >
                    {props.children}
                  </Form>
                  <Row className='align-items-center quote-form-row'>
                    <Col xs={12} sm={12}>
                      <SecuredCard />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center quote-form-row'>
            <Col xs={12} sm={12}>
              <div className='quote-sub-footer-wrap'>
                <SubFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

ShortFormLayout.propTypes = {
  stepId: PropTypes.string.isRequired,
  formOnSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  validated: PropTypes.bool,
};

export default ShortFormLayout;
