import 'react-app-polyfill/ie11';

import React, { useEffect, useState } from 'react';

import Cookies from 'universal-cookie';
import FormService from '../../service/formService';
import FormUtils from '../../utils/formUtils';
import Homepage from '../mainPage/Homepage';
import Layout from '../layout';
import PageLoader from '../subComponents/pageLoader';
import SentryService from '../../service/sentryService';
import ShortFormStep from './shortFormStep';
import UserService from '../../service/userService';
import { getSearchParams } from 'gatsby-query-params';
import propTypes from 'prop-types';

const cookies = new Cookies();
const maxAge = 365 * 24 * 60 * 60;
function ShortFormNavigationComponent({ isMedicareAdvantage }) {
  const [lead_id, setLead_id] = useState('');
  const [loading, setLoading] = useState(false);
  const [ap, setAP] = useState('');
  const [jlID, setJLID] = useState('');
  const qp = getSearchParams();
  const [callInNum, setCallInNum] = useState('');
  const [publicIP, setPublicIP] = useState('');
  const [cookieValues, setCookieValues] = useState({
    hotjarSessionCookie: '',
    leadClientIDGoogleCookie: '',
    originalLeadAdSourceCookie: '',
    originalLeadMediumCookie: '',
    originalLeadAdCampaignCookie: '',
    originalLeadAdGroupCookie: '',
    originalLeadDateCookie: '',
    originalLeadURLCookie: '',
    originalGoogleClientIDCookie: '',
    originalLeadNetwork: '',
    originalLeadMatchType: '',
    originalLeadKeyword: '',
    originalLeadQueryString: '',
    originalLeadContent: '',
    originalLeadExtension: '',
    originalLeadDevice: '',
    originalLeadLocation: '',
    originalLeadPlacement: '',
    originalLeadAdPosition: '',
    originalLeadDeviceBrand: '',
    originalLeadDeviceName: '',
    originalLeadOsVersion: '',
    originalLeadScreenHeight: '',
    originalLeadScreenWidth: '',
    originalLeadLandingPage: '',
    originalLeadReferringUrl: '',
    originalLeadIPAddress: '',
    originalLeadBrowser: '',
    originalLeadOS: '',
    originalLeadCampaignID: '',
    originalLeadAdGroupID: '',
    originalLeadKeywordID: '',
    originalLeadExtensionID: '',
  });

  // The state will contain all the data from the form steps
  // Each step will update the fields that correspond to them
  const [state, setState] = useState({
    selectedComponent: undefined,
    zipCode: '',
    county: '',
    stateAbbrv: '',
    day: '',
    month: '',
    year: '',
    gender: '',
    spouse: '',
    spouse_month: '',
    spouse_year: '',
    spouse_day: '',
    spouse_gender: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    sms_opt_in: 'false',
    // Image shown to user in homepage banner
    image_id: '',
    term_id: '',
  });

  var leadData = {
    postalCode: state.zipCode,
    firstName: state.first_name,
    lastName: state.last_name,
    birthdate: state.year + '-' + state.month + '-' + state.day,
    gender: state.gender,
    email: state.email,
    phone: parseInt(FormUtils.cleansePhone(state.phone)),
    spouse: state.spouse,
    spouseGender: state.spouse_gender,
    spouseBirthdate:
      state.spouse_year + '-' + state.spouse_month + '-' + state.spouse_day,
    leadAdId: qp.Lead_Ad_Id ? qp.Lead_Ad_Id.toString() : '',
    leadAdSource: qp.utm_source ? qp.utm_source.toString() : 'direct',
    leadMedium: qp.utm_medium ? qp.utm_medium.toString() : 'search',
    leadAdCampaign: qp.utm_campaign ? qp.utm_campaign.toString() : '',
    leadAdGroup: qp.adgroupid ? qp.adgroupid.toString() : '',
    leadKeyword: qp.utm_term ? qp.utm_term.toString() : '',
    leadNetwork: qp.network ? qp.network.toString() : '',
    leadAdPosition: qp.adposition ? qp.adposition.toString() : '',
    gclid: qp.gclid
      ? qp.gclid.toString()
      : qp.wbraid
      ? qp.wbraid.toString()
      : '',
    fbclid: qp.fbclid ? qp.fbclid.toString() : '',
    leadContent: qp.utm_content ? qp.utm_content.toString() : '',
    leadExtension: qp.extensionid ? qp.extensionid.toString() : '',
    leadMatchType: qp.matchtype ? qp.matchtype.toString() : '',
    leadLocation: qp.location ? qp.location.toString() : '',
    leadDevice: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().deviceType
      : '',
    msclkid: qp.msclkid ? qp.msclkid.toString() : '',
    leadPlacement: qp.placement ? qp.placement.toString() : '',
    leadQueryString: qp.querystring ? qp.querystring.toString() : '',
    excludeFromBoberdooAPICallouts: qp.info ? qp.info.toString() : '',
    leadBrowser: FormUtils.getBrowserName(),
    hotjarSession: cookieValues.hotjarSessionCookie,
    leadClientIDGoogle: cookieValues.leadClientIDGoogleCookie,
    activeProspect: ap,
    journayaLeadID: jlID,
    leadStatus: 'New',
    leadSubStatus: '',
    leadPageID: process.env.GATSBY_SITE_VERSION,
    smsOptIn: state.sms_opt_in,
    osName: FormUtils.getOS(),
    sampleQuote: '',
    matokenID: qp.MATOKENID ? qp.MATOKENID.toString() : '',
    sessionStartTime: new Date().toISOString(),

    leadDeviceBrand: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().deviceBrand
      : '',
    leadDeviceName: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().deviceName
      : '',
    leadOsVersion: FormUtils.getOSVersion(),
    leadScreenHeight: FormUtils.getWindow()
      ? FormUtils.getWindow().outerHeight
      : '',
    leadScreenWidth: FormUtils.getWindow()
      ? FormUtils.getWindow().outerWidth
      : '',
    leadLandingPage: FormUtils.getDocument()
      ? FormUtils.getDocument().location.origin +
        FormUtils.getDocument().location.pathname
      : '',
    leadReferringUrl:
      FormUtils.getDocument() && FormUtils.getDocument().referrer
        ? new URL(FormUtils.getDocument().referrer).hostname
        : '',
    campaignID: qp.campaignID ? qp.campaignID.toString() : '',
    adgroup_ID: qp.adgroup_ID ? qp.adgroup_ID.toString() : '',
    keywordID: qp.keywordID ? qp.keywordID.toString() : '',
    extensionID: qp.extensionid ? qp.extensionid.toString() : '',

    originalLeadAdSource: cookieValues.originalLeadAdSourceCookie,
    originalLeadMedium: cookieValues.originalLeadMediumCookie,
    originalLeadAdCampaign: cookieValues.originalLeadAdCampaignCookie,
    originalLeadAdGroup: cookieValues.originalLeadAdGroupCookie,
    originalLeadDate: cookieValues.originalLeadDateCookie,
    originalLeadURL: cookieValues.originalLeadURLCookie,
    originalGoogleClientID: cookieValues.originalGoogleClientIDCookie,

    originalLeadNetwork: cookieValues.originalLeadNetwork,
    originalLeadMatchType: cookieValues.originalLeadMatchType,
    originalLeadKeyword: cookieValues.originalLeadKeyword,
    originalLeadQueryString: cookieValues.originalLeadQueryString,
    originalLeadContent: cookieValues.originalLeadContent,
    originalLeadExtension: cookieValues.originalLeadExtension,
    originalLeadDevice: cookieValues.originalLeadDevice,
    originalLeadLocation: cookieValues.originalLeadLocation,
    originalLeadPlacement: cookieValues.originalLeadPlacement,
    originalLeadAdPosition: cookieValues.originalLeadAdPosition,
    originalLeadDeviceBrand: cookieValues.originalLeadDeviceBrand,
    originalLeadDeviceName: cookieValues.originalLeadDeviceName,
    originalLeadOsVersion: cookieValues.originalLeadOsVersion,
    originalLeadScreenHeight: cookieValues.originalLeadScreenHeight,
    originalLeadScreenWidth: cookieValues.originalLeadScreenWidth,
    originalLeadLandingPage: cookieValues.originalLeadLandingPage,
    originalLeadReferringUrl: cookieValues.originalLeadReferringUrl,
    originalLeadIPAddress: cookieValues.originalLeadIPAddress,
    originalLeadBrowser: cookieValues.originalLeadBrowser,
    originalLeadOS: cookieValues.originalLeadOS,
    originalLeadCampaignID: cookieValues.originalLeadCampaignID,
    originalLeadAdGroupID: cookieValues.originalLeadAdGroupID,
    originalLeadKeywordID: cookieValues.originalLeadKeywordID,
    originalLeadExtensionID: cookieValues.originalLeadExtensionID,
    stateAbbrv: state.stateAbbrv,
    countyFips: state.county,
    countyName: state.countyName,
    // Image shown to user in banner
    image_id: state.image_id,
    term_id: state.term_id,
  };

  const setHomePageDetails = (data) => {
    setState((prevState) => ({
      ...prevState,
      zipCode: data.zipCode,
      county: data.county,
      countyName: data.countyName,
      stateAbbrv: data.stateAbbrv,
      image_id: data.imageId,
      term_id: data.termId,
      selectedComponent: 'ShortForm',
    }));
    FormUtils.scrollToTop();
  };

  const setShortFormDetails = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
      selectedComponent: 'SecondaryForm',
    }));
    FormUtils.scrollToTop();
    FormUtils.getWindow().onbeforeunload = null;
  };

  const renderInternalComponent = () => {
    if (loading) return <PageLoader />;
    else
      switch (state.selectedComponent) {
        case 'ShortForm':
        case 'SecondaryForm':
          return <ShortFormStep onSubmit={setShortFormDetails} data={state} />;
        default:
          return (
            <Homepage
              onSubmit={setHomePageDetails}
              data={state}
              utmTerm={qp.utm_term}
              isMedicareAdvantage={isMedicareAdvantage}
            />
          );
      }
  };

  // Internal Methods Start
  const checkAP = () => {
    const apValue =
      cookies.get('trustedFormId') !== undefined
        ? cookies.get('trustedFormId')
        : '';
    setAP(apValue);
  };

  const checkLeadID = () => {
    const liD =
      cookies.get('externalLeadiD') !== undefined
        ? cookies.get('externalLeadiD')
        : '';
    console.log('External Lead ID Setting value :: ', liD);
    setJLID(liD);
    if (typeof window !== 'undefined' && window.LeadiD) {
      window.LeadiD.snap();
    }
  };

  const getLeadURL = () => {
    if (typeof window !== 'undefined') {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        window.location.pathname
      );
    } else {
      return '';
    }
  };

  const createSalesforceUser = () => {
    setLoading(true);
    // Fields for sending email
    leadData.subject = 'Rates.MedicareFAQ - Main Form Submission';
    leadData.fullURL = FormUtils.getURL();

    // Fields for SF Entry
    leadData.selectedChoice = createPlanString();
    leadData.leadSource = getLeadSource();
    leadData.leadURL = getLeadURL();
    leadData.redirectURL = /sunfirematrix/.test(decideUrl()) ? decideUrl() : '';
    leadData.sampleQuote = encodedData();
    leadData.leadIPAddress =
      cookies.get('lead_IP-Address') !== undefined
        ? cookies.get('lead_IP-Address')
        : '';
    leadData.mainFormReceived = true;
    FormService.createLeadForRates(leadData)
      .then(async function(data) {
        cookies.set('main_Form-Recieved', true, {
          path: '/',
          domain: '.medicarefaq.com',
          encode: unescape,
        });
        leadData.leadID = data.data;
        setLead_id((lead_id) => lead_id + data.data);
      })
      .catch(function(error) {
        window.location.href = '/thanks';
        SentryService.publishError(error, 'CreateSalesforceUser');
        console.error('Something went wrong with the lead: ', error);
      });
  };

  const createPlanString = () => {
    let planString = 'Medicare Supplement (Medigap)';
    if (isMedicareAdvantage) planString = 'Medicare Advantage (HMO, PPO, PFFS)';
    return planString;
  };

  const decideUrl = () => {
    let spouseOfAge = false;
    let hasSpouse = state.spouse === 'yes' ? true : false;
    let ofAge =
      FormUtils.getAge(state.year + '/' + state.month + '/' + state.day) >= 64;
    if (hasSpouse) {
      spouseOfAge =
        FormUtils.getAge(
          state.spouse_year + '/' + state.spouse_month + '/' + state.spouse_day
        ) >= 64;
    }
    if (!ofAge && (!hasSpouse || !spouseOfAge)) {
      return `https://www.sunfirematrix.com/app/consumer/yourmedicare/16423979/?defaultPlanType=MAPD#/plans/${state.zipCode}/${state.county}/MAPD`;
    } else {
      const query = `?spouse=${
        state.spouse
      }&lead_id=${lead_id}&callinnum=${callInNum}&zip=${state.zipCode}&state=${
        state.stateAbbrv
      }&gender=${state.gender.charAt(0).toUpperCase()}&age=${FormUtils.getAge(
        state.year + '/' + state.month + '/' + state.day
      )}`;
      const encodedQuery = `?info=${btoa(query)}`;
      return '/supplemental-information' + encodedQuery;
    }
  };

  const encodedData = () => {
    const query = `callinnum=${callInNum}&zip=${
      state.zipCode
    }&age=${FormUtils.getAge(
      state.year + '/' + state.month + '/' + state.day
    )}&gender=${state.gender.charAt(0).toUpperCase()}&state=${
      state.stateAbbrv
    }`;
    return `${btoa(query)}`;
  };

  const getLeadSource = () => {
    if (qp.utm_source === 'social' || qp.utm_source === 'fb') {
      return 'Facebook - Online';
    } else if (qp.utm_source === 'excelimpact' || qp.utm_source === 'apollo') {
      return 'Partner - Online';
    } else if (
      (leadData.email && leadData.email.includes('@test.com')) ||
      (leadData.lastName && leadData.lastName.toLowerCase() === 'test')
    ) {
      return 'Test';
    } else {
      return 'MedicareFAQ - Online';
    }
  };

  const saveCookieData = () => {
    setCookieValues({
      hotjarSessionCookie:
        cookies.get('hotjarId') !== undefined ? cookies.get('hotjarId') : '',
      leadClientIDGoogleCookie:
        cookies.get('_ga') !== undefined ? cookies.get('_ga') : '',
      originalLeadAdSourceCookie:
        cookies.get('marketing_Original-Lead-Ad-Source') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Source')
          : '',
      originalLeadMediumCookie:
        cookies.get('marketing_Original-Lead-Medium') !== undefined
          ? cookies.get('marketing_Original-Lead-Medium')
          : '',
      originalLeadAdCampaignCookie:
        cookies.get('marketing_Original-Lead-Ad-Campaign') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Campaign')
          : '',
      originalLeadAdGroupCookie:
        cookies.get('marketing_Original-Lead-Ad-Group') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Group')
          : '',
      originalLeadDateCookie:
        cookies.get('marketing_Original-Lead-Date') !== undefined
          ? cookies.get('marketing_Original-Lead-Date')
          : '',
      originalLeadURLCookie:
        cookies.get('marketing_Original-Lead-URL') !== undefined
          ? cookies.get('marketing_Original-Lead-URL')
          : '',
      originalGoogleClientIDCookie:
        cookies.get('marketing_Original-Google-Client-ID') !== undefined
          ? cookies.get('marketing_Original-Google-Client-ID')
          : '',
      originalLeadNetwork:
        cookies.get('marketing_Original-Lead-Network') !== undefined
          ? cookies.get('marketing_Original-Lead-Network')
          : '',
      originalLeadMatchType:
        cookies.get('marketing_Original-Lead-Match-Type') !== undefined
          ? cookies.get('marketing_Original-Lead-Match-Type')
          : '',
      originalLeadKeyword:
        cookies.get('marketing_Original-Lead-Keywords') !== undefined
          ? cookies.get('marketing_Original-Lead-Keywords')
          : '',
      originalLeadQueryString:
        cookies.get('marketing_Original-Lead-Query-String') !== undefined
          ? cookies.get('marketing_Original-Lead-Query-String')
          : '',
      originalLeadContent:
        cookies.get('marketing_Original-Lead-Content') !== undefined
          ? cookies.get('marketing_Original-Lead-Content')
          : '',
      originalLeadExtension:
        cookies.get('marketing_Original-Lead-Extension') !== undefined
          ? cookies.get('marketing_Original-Lead-Extension')
          : '',
      originalLeadDevice:
        cookies.get('marketing_Original-Lead-Device') !== undefined
          ? cookies.get('marketing_Original-Lead-Device')
          : '',
      originalLeadLocation:
        cookies.get('marketing_Original-Lead-Location') !== undefined
          ? cookies.get('marketing_Original-Lead-Location')
          : '',
      originalLeadPlacement:
        cookies.get('marketing_Original-Lead-Placement') !== undefined
          ? cookies.get('marketing_Original-Lead-Placement')
          : '',
      originalLeadAdPosition:
        cookies.get('marketing_Original-Lead-Ad-Position') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Position')
          : '',
      originalLeadDeviceName:
        cookies.get('marketing_Original-Lead-Device-Name') !== undefined
          ? cookies.get('marketing_Original-Lead-Device-Name')
          : '',
      originalLeadDeviceBrand:
        cookies.get('marketing_Original-Lead-Device-Brand') !== undefined
          ? cookies.get('marketing_Original-Lead-Device-Brand')
          : '',
      originalLeadOsVersion:
        cookies.get('marketing_Original-Lead-Lead-OS-Version') !== undefined
          ? cookies.get('marketing_Original-Lead-Lead-OS-Version')
          : '',
      originalLeadScreenHeight:
        cookies.get('marketing_Original-Lead-Screen-Height') !== undefined
          ? cookies.get('marketing_Original-Lead-Screen-Height')
          : '',
      originalLeadScreenWidth:
        cookies.get('marketing_Original-Lead-Screen-Width') !== undefined
          ? cookies.get('marketing_Original-Lead-Screen-Width')
          : '',
      originalLeadLandingPage:
        cookies.get('marketing_Original-Lead-Landing-Page') !== undefined
          ? cookies.get('marketing_Original-Lead-Landing-Page')
          : '',
      originalLeadReferringUrl:
        cookies.get('marketing_Original-Lead-Referring-URL') !== undefined
          ? cookies.get('marketing_Original-Lead-Referring-URL')
          : '',
      originalLeadIPAddress:
        cookies.get('marketing_Original-Lead-IP-Address') !== undefined
          ? cookies.get('marketing_Original-Lead-IP-Address')
          : '',
      originalLeadBrowser:
        cookies.get('marketing_Original-Lead-Lead-Browser') !== undefined
          ? cookies.get('marketing_Original-Lead-Lead-Browser')
          : '',
      originalLeadOS:
        cookies.get('marketing_Original-Lead-Operating-System') !== undefined
          ? cookies.get('marketing_Original-Lead-Operating-System')
          : '',
      originalLeadCampaignID:
        cookies.get('marketing_Original-Lead-Campaign-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Campaign-ID')
          : '',
      originalLeadAdGroupID:
        cookies.get('marketing_Original-Lead-Ad-Group-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Group-ID')
          : '',
      originalLeadKeywordID:
        cookies.get('marketing_Original-Lead-Keyword-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Keyword-ID')
          : '',
      originalLeadExtensionID:
        cookies.get('marketing_Original-Lead-Extension-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Extension-ID')
          : '',
    });
  };

  const createCookies = (publicIPAddr) => {
    let device = FormUtils.getDeviceDetect(),
      deviceAPI = FormUtils.getDeviceDetails();
    if (!('marketing_Original-Lead-Date' in cookies.getAll())) {
      cookies.set(
        'marketing_Original-Lead-Ad-Source',
        qp.utm_source ? qp.utm_source.toString() : 'direct',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Medium',
        qp.utm_medium ? qp.utm_medium.toString() : 'search',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Campaign',
        qp.utm_campaign ? qp.utm_campaign.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Group',
        qp.adgroupid ? qp.adgroupid.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Date', new Date().toISOString(), {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-URL', getLeadURL(), {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Google-Client-ID',
        cookies.get('_ga') ? cookies.get('_ga') : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
    }
    // * Cookies required for Invoca
    // * Additional check needed to set these values for people who already have visited rates but not have these values filled
    if (!('marketing_Original-Lead-Network' in cookies.getAll())) {
      cookies.set('marketing_Original-Lead-Network', leadData.leadNetwork, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Match-Type',
        leadData.leadMatchType,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Keywords', leadData.leadKeyword, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Query-String',
        leadData.leadQueryString,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Content', leadData.leadContent, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Extension', leadData.leadExtension, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Device', leadData.leadDevice, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Location', leadData.leadLocation, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Placement', leadData.leadPlacement, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Ad-Position',
        leadData.leadAdPosition,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Device-Name',
        deviceAPI ? deviceAPI.deviceName : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Device-Brand',
        deviceAPI ? deviceAPI.deviceBrand : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Lead-OS-Version', device.osVersion, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Screen-Height',
        FormUtils.getWindow() ? FormUtils.getWindow().outerHeight : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Screen-Width',
        FormUtils.getWindow() ? FormUtils.getWindow().outerWidth : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Landing-Page',
        FormUtils.getDocument()
          ? FormUtils.getDocument().location.origin +
              FormUtils.getDocument().location.pathname
          : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: encodeURI,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Referring-URL',
        FormUtils.getDocument() && FormUtils.getDocument().referrer
          ? new URL(FormUtils.getDocument().referrer).hostname
          : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: encodeURI,
        }
      );
      cookies.set('marketing_Original-Lead-IP-Address', publicIPAddr, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Lead-Browser',
        leadData.leadBrowser,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Operating-System', leadData.osName, {
        path: '/',
        maxAge: maxAge,
        domain: '.medicarefaq.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Campaign-ID',
        qp.campaignID ? qp.campaignID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Group-ID',
        qp.adgroup_ID ? qp.adgroup_ID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Keyword-ID',
        qp.keywordID ? qp.keywordID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Extension-ID',
        qp.extensionid ? qp.extensionid.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.medicarefaq.com',
          encode: unescape,
        }
      );
    }
  };

  useEffect(() => {
    if (state.selectedComponent === 'ShortForm') {
      // Handle the data here, the state variable contains all the form
      // data, send it then change the page to /supplemental-information
      saveCookieData();
    } else if (state.selectedComponent === 'SecondaryForm') {
      cookies.set('leadphone', state.phone.match(/\d/g).join(''), {
        path: '/',
        encode: unescape,
      });
    }
    return;
  }, [state]);

  useEffect(() => {
    if (state.selectedComponent === 'ShortForm') {
      checkAP();
      checkLeadID();
      let callInNum = document.getElementById('callInNum');
      if (callInNum) setCallInNum(callInNum.text);
      return;
    } else if (state.selectedComponent !== 'SecondaryForm') return;
    FormService.fireLeadConversionEvent();
    createSalesforceUser();
    // sendEmail();
  }, [state]);

  useEffect(() => {
    if (publicIP !== null) {
      UserService.getPublicIP()
        .then((publicIp) => {
          let url = window.location.href;
          setPublicIP(publicIp);
          if (Object.keys(qp).length !== 0 || !(url.indexOf('?') !== -1)) {
            createCookies(publicIp);
          }
          createCookiesForInvoca(publicIp);
        })
        .catch((err) => {
          console.log('Public IP Error', err);
          setPublicIP(null);
        });
    } else {
      let url = window.location.href;
      const publicIp = publicIP ? publicIP : '';
      if (Object.keys(qp).length !== 0 || !(url.indexOf('?') !== -1)) {
        createCookies(publicIp);
      }
      createCookiesForInvoca(publicIp);
    }
  }, [qp]);

  const createCookiesForInvoca = (publicIPAddr) => {
    cookies.set('lead_source', getLeadSource(), {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Source', leadData.leadAdSource, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Medium', leadData.leadMedium, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Campaign', leadData.leadAdCampaign, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Group', leadData.leadAdGroup, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Network', leadData.leadNetwork, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Match-Type', leadData.leadMatchType, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Keyword', leadData.leadKeyword, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Query-String', leadData.leadQueryString, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Content', leadData.leadContent, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Extension', leadData.leadExtension, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Device', leadData.leadDevice, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Location', leadData.leadLocation, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Placement', leadData.leadPlacement, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Position', leadData.leadAdPosition, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Client-ID-Google', leadData.leadClientIDGoogle, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Device-Brand', leadData.leadDeviceBrand, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Device-Name', leadData.leadDeviceName, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_OS-Version', leadData.leadOsVersion, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Screen-Height', leadData.leadScreenHeight, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Screen-Width', leadData.leadScreenWidth, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Landing-Page', leadData.leadLandingPage, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: encodeURI,
    });
    cookies.set('lead_URL', getLeadURL(), {
      path: '/',
      domain: '.medicarefaq.com',
      encode: encodeURI,
    });
    cookies.set('lead_Referring-URL', leadData.leadReferringUrl, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: encodeURI,
    });
    cookies.set('lead_IP-Address', publicIPAddr, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Browser', leadData.leadBrowser, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Operating-System', leadData.osName, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('google_Click-ID', leadData.gclid, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('ms_Click-ID', leadData.msclkid, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('fc_Click-ID', leadData.fbclid, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('main_Form-Recieved', false, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('sup-Form-Received', false, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('active_Prospect', leadData.activeProspect, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('jornaya-Lead-ID', leadData.journayaLeadID, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Campaign-ID', leadData.campaignID, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Group-ID', leadData.adgroup_ID, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Keyword-ID', leadData.keywordID, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set('lead_Extension-ID', leadData.extensionID, {
      path: '/',
      domain: '.medicarefaq.com',
      encode: unescape,
    });
    cookies.set(
      'marketing_Phone-Ad-Type',
      leadData.leadAdSource + ' - ' + leadData.leadMedium,
      { path: '/', domain: '.medicarefaq.com', encode: unescape }
    );
  };

  useEffect(() => {
    if (state.selectedComponent !== 'SecondaryForm') return;
    console.log('Redirection starting', lead_id);
    const redirectURL = decideUrl();
    if (redirectURL.startsWith('https') || redirectURL.startsWith('http'))
      window.location.href = redirectURL;
    else window.location.href = redirectURL;
  }, [lead_id]);

  // Internal Methods End

  return (
    <Layout
      header={!loading}
      footer={!loading}
      stickyFooter={
        state.selectedComponent === '' || state.selectedComponent === undefined
      }
    >
      {renderInternalComponent(state)}
    </Layout>
  );
}

ShortFormNavigationComponent.propTypes = {
  isMedicareAdvantage: propTypes.bool,
};

ShortFormNavigationComponent.defaultProps = {
  isMedicareAdvantage: false,
};

export default ShortFormNavigationComponent;
