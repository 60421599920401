import { Button, Col, Form, Row } from 'react-bootstrap';

import FormUtils from '../../utils/formUtils';
import InputText from '../subComponents/inputText';
import MandatoryLabel from '../subComponents/mandatoryLabel';
import React from 'react';
import SelectDay from '../subComponents/selectDay';
import SelectGender from '../subComponents/selectGender';
import SelectMonth from '../subComponents/selectMonth';
import SelectYear from '../subComponents/selectYear';
import ShortFormLayout from '../layout/shortFormLayout';
import UserService from '../../service/userService';
import YesNoQuestion from '../subComponents/yesNoQuestion';
import { isEmpty } from 'lodash';
import propTypes from 'prop-types';

const phoneNumberPattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const namePattern = /[a-zA-Z\s]{2,}/;
const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;

class ShortFormStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      month: '',
      day: '',
      year: '',
      gender: '',
      view_partner_rates: false,
      spouse_month: '',
      spouse_day: '',
      spouse_year: '',
      spouse_gender: '',
      first_name: '',
      is_first_name_valid: false,
      last_name: '',
      is_last_name_valid: false,
      email: '',
      is_email_valid: '',
      phone: '',
      is_phone_valid: '',
      sms_opt_in: false,
      validated: false,
      phone_exchange_type: '',
    };
  }

  onDayChange = (day) => this.setState({ day });

  onMonthChange = (month) => this.setState({ month });

  onYearChange = (year) => this.setState({ year });

  onGenderChange = (gender) => this.setState({ gender });

  onViewPartnerRatesChange = (value) => {
    if (value)
      this.setState({
        view_partner_rates: value,
      });
    else {
      // Reset Spouse Age and Gender details if no household discounts availed
      this.setState({
        view_partner_rates: value,
        spouse_month: '',
        spouse_day: '',
        spouse_year: '',
        spouse_gender: '',
      });
    }
  };

  onSpouseDayChange = (spouse_day) => this.setState({ spouse_day });

  onSpouseMonthChange = (spouse_month) => this.setState({ spouse_month });

  onSpouseYearChange = (spouse_year) => this.setState({ spouse_year });

  onSpouseGenderChange = (spouse_gender) => this.setState({ spouse_gender });

  onSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      FormUtils.scrollToTop();
      this.setState({ validated: true });
      event.stopPropagation();
    } else {
      const {
        day,
        month,
        year,
        gender,
        view_partner_rates,
        spouse_day,
        spouse_month,
        spouse_year,
        spouse_gender,
        first_name,
        last_name,
        email,
        phone,
        sms_opt_in,
      } = this.state;
      const data = {
        day,
        month,
        year,
        gender,
        spouse: view_partner_rates ? 'yes' : 'no',
        spouse_day,
        spouse_month,
        spouse_year,
        spouse_gender,
        first_name,
        last_name,
        email,
        phone,
        sms_opt_in: sms_opt_in ? 'true' : 'false',
      };
      this.props.onSubmit(data);
    }
  };

  // Disable the final button if inputs are not filled, if personal details are not valid
  submitBtnDisabled = () => {
    const {
      day,
      month,
      year,
      gender,
      view_partner_rates,
      spouse_day,
      spouse_month,
      spouse_year,
      spouse_gender,
      first_name,
      is_first_name_valid,
      last_name,
      is_last_name_valid,
      email,
      is_email_valid,
      phone,
      is_phone_valid,
    } = this.state;
    if (
      isEmpty(day) ||
      isEmpty(month) ||
      isEmpty(year) ||
      isEmpty(gender) ||
      isEmpty(first_name) ||
      isEmpty(last_name) ||
      isEmpty(email) ||
      isEmpty(phone)
    ) {
      return true;
    } else if (
      view_partner_rates &&
      (isEmpty(spouse_day) ||
        isEmpty(spouse_gender) ||
        isEmpty(spouse_month) ||
        isEmpty(spouse_year))
    ) {
      return true;
    } else if (
      !is_first_name_valid ||
      !is_last_name_valid ||
      !is_email_valid ||
      !is_phone_valid
    ) {
      return true;
    } else return false;
  };

  validateCustomerName = (name, is_first_name) => {
    if (is_first_name)
      this.setState({
        is_first_name_valid: namePattern.test(name),
      });
    else
      this.setState({
        is_last_name_valid: namePattern.test(name),
      });
  };

  validatePhoneNumber = (phone) => {
    this.setState({ phone_exchange_type: '' });
    if (phoneNumberPattern.test(phone)) {
      UserService.validatePhoneNumber(phone)
        .then((response) => {
          if (
            response.status === 'invalid' ||
            response.status === 'bad_request'
          ) {
            throw response;
          } else {
            this.setState({
              is_phone_valid: true,
              phone_exchange_type: response.exchange_type,
            });
          }
        })
        .catch(() => {
          this.setState({ phone_exchange_type: '', is_phone_valid: false });
        });
    }
  };

  validateEmail = (email) => {
    this.setState({
      is_email_valid: emailPattern.test(email),
    });
  };

  render() {
    const {
      day,
      month,
      year,
      gender,
      view_partner_rates,
      spouse_day,
      spouse_month,
      spouse_year,
      spouse_gender,
      first_name,
      is_first_name_valid,
      last_name,
      is_last_name_valid,
      email,
      is_email_valid,
      phone,
      is_phone_valid,
      validated,
      phone_exchange_type,
    } = this.state;
    return (
      <ShortFormLayout
        stepId='step-5-form'
        formOnSubmit={this.onSubmit}
        validated={validated}
      >
        {/* Date of birth */}
        <Row className='align-items-center quote-form-row'>
          <Col xs={12} sm={12}>
            <h4>
              Date of Birth
              <MandatoryLabel />
            </h4>
          </Col>
          <Col xs={12} sm={4}>
            <SelectMonth
              onChange={this.onMonthChange}
              data={month}
              required={true}
            />
          </Col>
          <Col xs={12} sm={4}>
            <SelectDay onChange={this.onDayChange} data={day} required={true} />
          </Col>
          <Col xs={12} sm={4}>
            <SelectYear
              onChange={this.onYearChange}
              data={year}
              required={true}
            />
          </Col>
        </Row>

        {/* Gender */}
        <Row className='align-items-center quote-form-row mt20 xs-mt10'>
          <Col xs={12} sm={12}>
            <h4>
              Gender
              <MandatoryLabel />
            </h4>
          </Col>
          <SelectGender
            onChange={this.onGenderChange}
            data={gender}
            required={true}
          />
        </Row>

        <hr className='section-split' />

        {/* Household Discounts */}
        <Row className='align-items-center quote-form-row'>
          <Col xs={12} sm={12}>
            <h4>
              Household discounts up to 15% may be available for each member.{' '}
              Would you like to view rates for a partner/spouse?
            </h4>
          </Col>
          <Col xs={12} sm={12}>
            <YesNoQuestion
              idPrefix='rates-for-spouse'
              defaultValue={view_partner_rates}
              onChange={this.onViewPartnerRatesChange}
            />
          </Col>
        </Row>
        <hr className='section-split' />
        {/* Spouse Details */}
        {view_partner_rates && (
          <>
            {/* Spouse Date of Birth */}
            <Row className='align-items-center quote-form-row mt20 xs-mt10'>
              <Col xs={12} sm={12}>
                <h4>
                  Spouse/Partner Date of Birth
                  <MandatoryLabel />
                </h4>
              </Col>
              <Col xs={12} sm={4}>
                <SelectMonth
                  onChange={this.onSpouseMonthChange}
                  data={spouse_month}
                  idPrefix='spouse'
                  required={true}
                />
              </Col>
              <Col xs={12} sm={4}>
                <SelectDay
                  onChange={this.onSpouseDayChange}
                  data={spouse_day}
                  idPrefix='spouse'
                  required={true}
                />
              </Col>
              <Col xs={12} sm={4}>
                <SelectYear
                  onChange={this.onSpouseYearChange}
                  data={spouse_year}
                  idPrefix='spouse'
                  required={true}
                />
              </Col>
            </Row>

            {/* Spouse Gender */}
            <Row className='align-items-center quote-form-row mt20 xs-mt10'>
              <Col xs={12} sm={12}>
                <h4>
                  Spouse/Partner Gender
                  <MandatoryLabel />
                </h4>
              </Col>
              <SelectGender
                onChange={this.onSpouseGenderChange}
                data={spouse_gender}
                idPrefix='spouse'
                required={true}
              />
            </Row>
            <hr className='section-split' />
          </>
        )}

        {/* Personal Details */}
        <Row className='quote-form-row mt20 xs-mt10'>
          {/* First Name */}
          <Col xs={12} sm={6}>
            <h4>
              First Name
              <MandatoryLabel />
            </h4>
            <InputText
              id='first-name'
              type='text'
              placeholder='First Name'
              value={first_name}
              onChange={(event) => {
                this.setState({
                  first_name: event.target.value,
                });
                this.validateCustomerName(event.target.value, true);
              }}
              onBlur={(event) => {
                this.setState({
                  first_name: FormUtils.cleanString(event.target.value),
                });
                this.validateCustomerName(event.target.value, true);
              }}
              isInvalid={first_name !== '' && !is_first_name_valid}
              required={true}
              pattern='[a-zA-Z\s]{2,}'
              errorMessage='Please fill your first name'
              name='fname'
            />
          </Col>

          {/* Last Name */}
          <Col xs={12} sm={6}>
            <h4>
              Last Name
              <MandatoryLabel />
            </h4>
            <InputText
              id='last-name'
              type='text'
              placeholder='Last Name'
              value={last_name}
              onChange={(event) => {
                this.setState({
                  last_name: event.target.value,
                });
                this.validateCustomerName(event.target.value, false);
              }}
              onBlur={(event) => {
                this.setState({
                  last_name: FormUtils.cleanString(event.target.value),
                });
                this.validateCustomerName(event.target.value);
              }}
              isInvalid={last_name !== '' && !is_last_name_valid}
              required={true}
              pattern='[a-zA-Z\s]{2,}'
              errorMessage='Please fill your last name'
              name='lname'
            />
          </Col>

          {/* Email Address */}
          <Col xs={12}>
            <h4>
              E-mail Address
              <MandatoryLabel />
            </h4>
            <InputText
              id='email'
              type='email'
              placeholder='ex: name@email.com'
              onChange={(event) => {
                this.setState({
                  email: event.target.value,
                });
                this.validateEmail(event.target.value);
              }}
              onBlur={(event) => {
                this.setState({
                  email: FormUtils.cleanString(event.target.value),
                });
                this.validateEmail(event.target.value);
              }}
              isInvalid={email !== '' && !is_email_valid}
              value={email}
              required={true}
              errorMessage='Please fill a valid email'
              name='email'
            />
          </Col>

          {/* Phone Number  */}
          <Col xs={12}>
            <h4>
              Phone Number
              <MandatoryLabel />
            </h4>
            <InputText
              id='phone'
              placeholder='(123) 456-7890'
              type='tel'
              value={phone}
              onChange={(event) => {
                this.setState({
                  phone: event.target.value,
                });
                this.validatePhoneNumber(event.target.value);
              }}
              required={true}
              pattern='^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$'
              isInvalid={phone !== '' && !is_phone_valid}
              errorMessage='Please provide a valid phone number'
              name='phone'
            />
          </Col>

          {/* SMS Opt In */}
          {phone_exchange_type === 'cell' && (
            <Col xs={12}>
              <Form.Group className='sm-mb30 xs-mb20'>
                <Form.Check
                  id='text-msg-checkbox'
                  inline
                  label='Yes, I want to receive text messages to review my personalized quote.'
                  name='receive_text'
                  type='checkbox'
                  onChange={(event) => {
                    this.setState({ sms_opt_in: event.target.checked });
                  }}
                />
              </Form.Group>
            </Col>
          )}
        </Row>

        <Row className='align-items-center quote-form-row'>
          <Col xs={12} sm={12}>
            <Button
              id='step-5-submit-btn'
              variant='btn btn-danger-custom w-100 mt30 xs-mb15'
              type='submit'
              disabled={this.submitBtnDisabled()}
            >
              <span>Get my quotes</span>
            </Button>
          </Col>
        </Row>
      </ShortFormLayout>
    );
  }
}

ShortFormStep.propTypes = {
  onSubmit: propTypes.func.isRequired,
};

export default ShortFormStep;
