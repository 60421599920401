import { Col, Form, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import femaleImg from '../../images/female.svg';
import maleImg from '../../images/male.svg';

class SelectGender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGender: props.data ? props.data : '',
    };
  }

  getGender = () => {
    return this.state.selectedGender;
  };

  onGenderChange = (event) => {
    this.setState({
      selectedGender: event.target.value,
    });
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const { selectedGender } = this.state;
    const { idPrefix } = this.props;

    return (
      <Col xs={12}>
        <Form.Group id='gender-div'>
          <Row>
            <Col xs={6} sm={6} className='xs-pr10'>
              <div
                className='form-group-checkbox xs-gender-radiobox'
                id={idPrefix ? `${idPrefix}-gender-male` : 'gender-male'}
              >
                <input
                  type='radio'
                  name={idPrefix ? `${idPrefix}-gender` : 'gender'}
                  id={idPrefix ? `${idPrefix}-male` : 'male'}
                  value='male'
                  onChange={this.onGenderChange}
                  checked={selectedGender === 'male'}
                  aria-checked={selectedGender === 'male'}
                />
                <label
                  className='xs-gender-radiobox-label'
                  htmlFor={idPrefix ? `${idPrefix}-male` : 'male'}
                >
                  <span className='fa-check-span'>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <img src={maleImg} alt='Male' className='com-img' />
                  <p className='p18'>Male</p>
                </label>
              </div>
            </Col>
            <Col xs={6} sm={6} className='xs-pl10'>
              <div
                className='form-group-checkbox xs-gender-radiobox'
                id={idPrefix ? `${idPrefix}-gender-female` : 'gender-female'}
              >
                <input
                  tabIndex='0'
                  type='radio'
                  name={idPrefix ? `${idPrefix}-gender` : 'gender'}
                  id={idPrefix ? `${idPrefix}-female` : 'female'}
                  value='female'
                  checked={selectedGender === 'female'}
                  aria-checked={selectedGender === 'female'}
                  onChange={this.onGenderChange}
                />
                <label
                  className='xs-gender-radiobox-label'
                  htmlFor={idPrefix ? `${idPrefix}-female` : 'female'}
                >
                  <span className='fa-check-span'>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <img src={femaleImg} alt='Female' className='com-img' />
                  <p className='p18'>Female</p>
                </label>
              </div>
            </Col>
          </Row>
        </Form.Group>
      </Col>
    );
  }
}

SelectGender.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.string,
  idPrefix: PropTypes.string,
};

export default SelectGender;
