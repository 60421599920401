import React, { Component } from 'react';

import { Form } from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';
import PropTypes from 'prop-types';

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      id,
      type,
      placeholder,
      value,
      onChange,
      onBlur,
      isInvalid,
      pattern,
      required,
      errorMessage,
      name,
    } = this.props;

    const attributes = {
      id,
      type,
      placeholder,
      value,
      onChange,
      isInvalid,
      required,
      name,
    };

    if (pattern) attributes.pattern = pattern;

    return type !== 'tel' ? (
      <Form.Group className='sm-mb30 xs-mb20'>
        <Form.Control {...attributes} onBlur={onBlur} />
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    ) : (
      <Form.Group className='sm-mb30 xs-mb20'>
        <Form.Control
          {...attributes}
          as={MaskedInput}
          mask='(111) 111-1111'
          autoComplete='true'
        />
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
}

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'tel']),
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
};

InputText.defaultProps = {
  type: 'text',
};

export default InputText;
