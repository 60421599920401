import React, { Component } from 'react';

import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class YesNoQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.defaultValue !== '' ? props.defaultValue : null,
    };
  }

  onChange = (value) => {
    this.setState({
      selectedValue: value,
    });
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { selectedValue } = this.state;

    return (
      <ul className='list-inline yes-now-btn-wrap sm-mb30 xs-mb30 sm-mt20 xs-mt10'>
        <li className='list-inline-item mr20'>
          <Button
            id={`${this.props.idPrefix}-yes-btn`}
            variant='btn btn-sm btn-yes'
            onClick={() => this.onChange(true)}
            className={selectedValue === true ? 'active' : ''}
          >
            <span>Yes</span>
          </Button>
        </li>
        <li className='list-inline-item'>
          <Button
            id={`${this.props.idPrefix}-no-btn`}
            variant='btn btn-sm btn-yes'
            onClick={() => this.onChange(false)}
            className={selectedValue === false ? 'active' : ''}
          >
            <span>No</span>
          </Button>
        </li>
      </ul>
    );
  }
}
YesNoQuestion.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  idPrefix: PropTypes.string.isRequired,
};
export default YesNoQuestion;
